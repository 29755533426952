const PricetagIcon = ({
	size = 'large',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		height={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			minWidth: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
			minHeight: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
		}}
	>
		<path
			d="M1.44702 0H5.18871C5.57249 0 5.94054 0.152454 6.21191 0.423823L11.7881 6C12.0706 6.28255 12.0706 6.74065 11.7881 7.0232L7.0232 11.7881C6.74065 12.0706 6.28255 12.0706 6 11.7881L0.423823 6.21191C0.152453 5.94054 0 5.57249 0 5.18871V1.44702C0 0.647854 0.647854 0 1.44702 0ZM2.66667 3.66667C3.21895 3.66667 3.66667 3.21895 3.66667 2.66667C3.66667 2.11438 3.21895 1.66667 2.66667 1.66667C2.11438 1.66667 1.66667 2.11438 1.66667 2.66667C1.66667 3.21895 2.11438 3.66667 2.66667 3.66667Z"
			fill="currentColor"
			fillRule="evenodd"
			clipRule="evenodd"
		/>
	</svg>
)

export default PricetagIcon
