const TimerIcon = ({
	size = 'large',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		height={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 15 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			minWidth: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
			minHeight: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
		}}
	>
		<path
			d="M4 1.33341C4 0.965225 4.29848 0.666748 4.66667 0.666748H7.33333C7.70152 0.666748 8 0.965225 8 1.33341C8 1.7016 7.70152 2.00008 7.33333 2.00008H4.66667C4.29848 2.00008 4 1.7016 4 1.33341ZM10.1953 2.19534C10.4556 1.93499 10.8777 1.93499 11.1381 2.19534L12.4714 3.52868C12.7318 3.78903 12.7318 4.21114 12.4714 4.47149C12.2111 4.73184 11.7889 4.73184 11.5286 4.47149L10.1953 3.13815C9.93491 2.8778 9.93491 2.45569 10.1953 2.19534ZM6 4.00008C3.42267 4.00008 1.33333 6.08942 1.33333 8.66675C1.33333 11.2441 3.42267 13.3334 6 13.3334C8.57733 13.3334 10.6667 11.2441 10.6667 8.66675C10.6667 6.08942 8.57733 4.00008 6 4.00008ZM0 8.66675C0 5.35304 2.68629 2.66675 6 2.66675C9.31371 2.66675 12 5.35304 12 8.66675C12 11.9805 9.31371 14.6667 6 14.6667C2.68629 14.6667 0 11.9805 0 8.66675ZM6 5.33342C6.36819 5.33342 6.66667 5.63189 6.66667 6.00008V8.66675C6.66667 9.03494 6.36819 9.33342 6 9.33342C5.63181 9.33342 5.33333 9.03494 5.33333 8.66675V6.00008C5.33333 5.63189 5.63181 5.33342 6 5.33342Z"
			fill="currentColor"
			fillRule="evenodd"
			clipRule="evenodd"
		/>
	</svg>
)

export default TimerIcon
