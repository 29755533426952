const AudioIcon = ({
	size = 'large',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		height={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			minWidth: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
			minHeight: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
		}}
	>
		<path
			d="M5.25 6.375C4.62872 6.375 4.125 6.87865 4.125 7.5V16.5C4.125 17.1213 4.62872 17.625 5.25 17.625C5.87128 17.625 6.375 17.1213 6.375 16.5V7.5C6.375 6.87865 5.87128 6.375 5.25 6.375Z"
			fill="currentColor"
		/>
		<path
			d="M8.625 8.625C8.00372 8.625 7.5 9.12865 7.5 9.75V14.25C7.5 14.8713 8.00372 15.375 8.625 15.375C9.24628 15.375 9.75 14.8713 9.75 14.25V9.75C9.75 9.12865 9.24628 8.625 8.625 8.625Z"
			fill="currentColor"
		/>
		<path
			d="M12 3C11.3787 3 10.875 3.50365 10.875 4.125V19.875C10.875 20.4963 11.3787 21 12 21C12.6213 21 13.125 20.4963 13.125 19.875V4.125C13.125 3.50365 12.6213 3 12 3Z"
			fill="currentColor"
		/>
		<path
			d="M15.375 5.8125C14.7537 5.8125 14.25 6.31615 14.25 6.9375V17.0625C14.25 17.6838 14.7537 18.1875 15.375 18.1875C15.9963 18.1875 16.5 17.6838 16.5 17.0625V6.9375C16.5 6.31615 15.9963 5.8125 15.375 5.8125Z"
			fill="currentColor"
		/>
		<path
			d="M18.75 8.625C18.1287 8.625 17.625 9.12865 17.625 9.75V14.25C17.625 14.8713 18.1287 15.375 18.75 15.375C19.3713 15.375 19.875 14.8713 19.875 14.25V9.75C19.875 9.12865 19.3713 8.625 18.75 8.625Z"
			fill="currentColor"
		/>
	</svg>
)

export default AudioIcon
