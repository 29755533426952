const XIcon = ({
	size = 'large',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		height={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			minWidth: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
			minHeight: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
		}}
	>
		<path
			d="M3 20.9798C3.02198 20.9619 3.04662 20.9463 3.06558 20.9258C4.75067 19.0902 6.43523 17.2542 8.11997 15.4182C8.72367 14.7603 9.32738 14.1025 9.93108 13.4447C9.94898 13.4252 9.96618 13.4048 9.99081 13.3764C7.67968 10.2573 5.36838 7.13829 3.0436 4.00125C3.09536 4.00125 3.1262 4.00125 3.15722 4.00125C4.87652 4.00125 6.59582 4.00161 8.31512 4C8.37733 4 8.41739 4.01699 8.45532 4.06832C9.75064 5.81791 11.0472 7.56643 12.3438 9.31513C12.5453 9.58696 12.7465 9.85898 12.9579 10.1442C13.0115 10.0861 13.0607 10.0326 13.1098 9.97916C14.3571 8.61944 15.6044 7.25972 16.8517 5.9C17.4132 5.28783 17.9744 4.67548 18.5379 4.06528C18.5664 4.03434 18.6151 4.00447 18.6547 4.00411C19.1391 4 19.6235 4.00143 20.1081 4.00179C20.1198 4.00179 20.1313 4.00429 20.1655 4.00823C17.9921 6.37732 15.829 8.73551 13.6628 11.0966C16.108 14.3951 18.5496 17.6886 21 20.9943C20.958 20.9964 20.9328 20.9989 20.9077 20.9989C19.1795 20.9991 17.4515 20.9989 15.7233 21C15.6688 21 15.6324 20.9871 15.5977 20.9401C14.5574 19.5337 13.5154 18.1289 12.4739 16.7236C11.9028 15.953 11.3319 15.182 10.761 14.4112C10.7434 14.3874 10.7248 14.3643 10.6808 14.3078C10.6622 14.3429 10.6525 14.3763 10.6314 14.3994C9.40288 15.7401 8.17332 17.0798 6.94411 18.4199C6.17007 19.2636 5.39621 20.1078 4.62057 20.9499C4.59629 20.9762 4.55127 20.997 4.51582 20.9971C4.01066 20.9996 3.50569 20.9982 3.00053 20.9979V20.98L3 20.9798ZM18.6797 19.8286C18.6477 19.7834 18.6297 19.7567 18.6105 19.7308C17.2757 17.9295 15.941 16.1282 14.606 14.3271C12.362 11.2997 10.1179 8.27267 7.87519 5.24419C7.83531 5.19036 7.79543 5.17015 7.72914 5.17033C6.97601 5.17283 6.22271 5.17194 5.46959 5.17194H5.36501C5.40578 5.22827 5.43431 5.26833 5.46374 5.30804C6.37603 6.53917 7.2885 7.77013 8.20097 9.00108C10.8568 12.5843 13.5129 16.1674 16.1677 19.7515C16.2086 19.807 16.2507 19.8307 16.3208 19.8306C17.0711 19.8279 17.8214 19.829 18.5715 19.829C18.6002 19.829 18.629 19.829 18.6797 19.829V19.8286Z"
			fill="currentColor"
		/>
	</svg>
)

export default XIcon
