const NewsIcon = ({
	size = 'large',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		height={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			minWidth: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
			minHeight: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
		}}
	>
		<path
			d="M7 6.5C6.72386 6.5 6.5 6.72386 6.5 7V10C6.5 10.2761 6.72386 10.5 7 10.5H13C13.2761 10.5 13.5 10.2761 13.5 10V7C13.5 6.72386 13.2761 6.5 13 6.5H7Z"
			fill="currentColor"
		/>
		<path
			d="M13.5 13.5C13.5 13.7761 13.2761 14 13 14H7C6.72386 14 6.5 13.7761 6.5 13.5V12.5C6.5 12.2239 6.72386 12 7 12H13C13.2761 12 13.5 12.2239 13.5 12.5V13.5Z"
			fill="currentColor"
		/>
		<path
			d="M6.5 17C6.5 17.2761 6.72386 17.5 7 17.5H13C13.2761 17.5 13.5 17.2761 13.5 17V16C13.5 15.7239 13.2761 15.5 13 15.5H7C6.72386 15.5 6.5 15.7239 6.5 16V17Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 5C3 3.89543 3.89543 3 5 3H15C16.1046 3 17 3.89543 17 5V18C17 18.5523 17.4477 19 18 19C18.5523 19 19 18.5523 19 18V7H18.5C18.2239 7 18 6.77614 18 6.5V5.5C18 5.22386 18.2239 5 18.5 5H19C20.1046 5 21 5.89543 21 7V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V5ZM15.1707 19C15.0602 18.6872 15 18.3506 15 18V5H5V18C5 18.5523 5.44772 19 6 19H15.1707Z"
			fill="currentColor"
		/>
	</svg>
)

export default NewsIcon
