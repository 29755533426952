const CountersignIcon = ({
	size = 'large',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		height={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			minWidth: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
			minHeight: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
		}}
	>
		<path
			d="M5.83335 10L9.95814 14.1248L18.7977 5.28607M1.70819 10.0419L5.83298 14.1667M14.6717 5.32798L10.2523 9.7474"
			stroke="currentColor"
			strokeWidth="1.8"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default CountersignIcon
