const CollectionIcon = ({
	size = 'large',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		height={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			minWidth: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
			minHeight: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
		}}
	>
		<path
			d="M4.5 6.90015V8.10015C4.5 8.94023 4.5 9.36004 4.66349 9.68091C4.8073 9.96316 5.0366 10.1929 5.31885 10.3367C5.6394 10.5 6.05925 10.5 6.89768 10.5H8.10232C8.94075 10.5 9.35999 10.5 9.68055 10.3367C9.96279 10.1929 10.1929 9.96316 10.3367 9.68091C10.5 9.36036 10.5 8.94112 10.5 8.10268V6.89768C10.5 6.05925 10.5 5.6394 10.3367 5.31885C10.1929 5.0366 9.96279 4.8073 9.68055 4.66349C9.35968 4.5 8.94023 4.5 8.10015 4.5H6.90015C6.06007 4.5 5.63972 4.5 5.31885 4.66349C5.0366 4.8073 4.8073 5.0366 4.66349 5.31885C4.5 5.63972 4.5 6.06007 4.5 6.90015Z"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.5 15.9001V17.1001C4.5 17.9402 4.5 18.36 4.66349 18.6809C4.8073 18.9632 5.0366 19.1929 5.31885 19.3367C5.6394 19.5 6.05925 19.5 6.89768 19.5H8.10232C8.94075 19.5 9.35999 19.5 9.68055 19.3367C9.96279 19.1929 10.1929 18.9632 10.3367 18.6809C10.5 18.3604 10.5 17.9411 10.5 17.1027V15.8977C10.5 15.0592 10.5 14.6394 10.3367 14.3188C10.1929 14.0366 9.96279 13.8073 9.68055 13.6635C9.35968 13.5 8.94023 13.5 8.10015 13.5H6.90015C6.06007 13.5 5.63972 13.5 5.31885 13.6635C5.0366 13.8073 4.8073 14.0366 4.66349 14.3188C4.5 14.6397 4.5 15.0601 4.5 15.9001Z"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.5 6.90015V8.10015C13.5 8.94023 13.5 9.36004 13.6635 9.68091C13.8073 9.96316 14.0366 10.1929 14.3188 10.3367C14.6394 10.5 15.0592 10.5 15.8977 10.5H17.1023C17.9408 10.5 18.36 10.5 18.6805 10.3367C18.9628 10.1929 19.1929 9.96316 19.3367 9.68091C19.5 9.36036 19.5 8.94112 19.5 8.10268V6.89768C19.5 6.05925 19.5 5.6394 19.3367 5.31885C19.1929 5.0366 18.9628 4.8073 18.6805 4.66349C18.3597 4.5 17.9402 4.5 17.1001 4.5H15.9001C15.0601 4.5 14.6397 4.5 14.3188 4.66349C14.0366 4.8073 13.8073 5.0366 13.6635 5.31885C13.5 5.63972 13.5 6.06007 13.5 6.90015Z"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.5 15.9001V17.1001C13.5 17.9402 13.5 18.36 13.6635 18.6809C13.8073 18.9632 14.0366 19.1929 14.3188 19.3367C14.6394 19.5 15.0592 19.5 15.8977 19.5H17.1023C17.9408 19.5 18.36 19.5 18.6805 19.3367C18.9628 19.1929 19.1929 18.9632 19.3367 18.6809C19.5 18.3604 19.5 17.9411 19.5 17.1027V15.8977C19.5 15.0592 19.5 14.6394 19.3367 14.3188C19.1929 14.0366 18.9628 13.8073 18.6805 13.6635C18.3597 13.5 17.9402 13.5 17.1001 13.5H15.9001C15.0601 13.5 14.6397 13.5 14.3188 13.6635C14.0366 13.8073 13.8073 14.0366 13.6635 14.3188C13.5 14.6397 13.5 15.0601 13.5 15.9001Z"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default CollectionIcon
