import { AttestationType, CollectionNFTEntry, TargetWallet } from '#app/server/db.server.ts'
import { eventData, nft, nftCollectionInfo } from '#types/loreTypes.tsx'
import { PublicKey } from '@solana/web3.js'
import snakecase from 'lodash.snakecase'
import uniq from 'lodash.uniq'
import { CollectionType } from './templates/utils/templateTypes'
import {
	EthereumIcon,
	SolanaIcon,
	BaseIcon,
	TezosIcon,
	EtherscanIcon,
	ExchangeArtIcon,
	HighlightIcon,
	ObjktIcon,
	OpenSeaIcon,
	PolygonIcon,
	ShapeIcon,
	SolanaFMIcon,
	TZKTIcon,
	ZoraIcon,
	BidIcon,
	CommentIcon,
	FarcasterIcon,
	PlaceholderIcon,
	PricetagIcon,
	NewsIcon,
	TimerIcon,
	XIcon,
	CountersignIcon,
	RollupIcon,
	InstagramIcon,
	BulkIcon,
	ImageIcon,
	VideoIcon,
	AudioIcon,
	FileIcon,
	VerifiedIcon,
	CollectionIcon,
} from '#app/components/icons'
import { type selectOption } from '#app/components/common/dropdown'
import fallbackAvatarImage from '#/images/avatar11white.png'
import { PDFDocument } from 'pdf-lib'
import { validateAddress } from '@taquito/utils'

/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Error} if condition is falsey
 */
export function invariant(
	condition: any,
	message: string | (() => string),
): asserts condition {
	if (!condition) {
		throw new Error(typeof message === 'function' ? message() : message)
	}
}

export function getErrorMessage(error: unknown) {
	if (typeof error === 'string') return error
	if (
		error &&
		typeof error === 'object' &&
		'message' in error &&
		typeof error.message === 'string'
	) {
		return error.message
	}
	console.error('Unable to get error message for error', error)
	return 'Unknown Error'
}

export function getVercelDomainUrl() {
	if (import.meta.env.VITE_VERCEL_URL) {
		return `https://${import.meta.env.VITE_VERCEL_URL}`
	} else {
		return 'http://localhost:3000'
	}
}

export function getDomainUrl(request: Request) {
	const host =
		request.headers.get('X-Forwarded-Host') ??
		request.headers.get('host') ??
		new URL(request.url).host
	const protocol = request.headers.get('X-Forwarded-Proto') ?? 'http'
	return `${protocol}://${host}`
}

export const formatString = (originalString: string) => {
	try {
		// Normalize the string using NFKD form
		let normalizedString = originalString.normalize('NFKD')
		// Use a regular expression to remove combining diacritical marks
		let plainString = normalizedString.replace(/[\u0300-\u036F]/g, '')
		return plainString
	} catch (e) {
		return originalString
	}
}

export const isNumeric = (str: string) => {
	if (typeof str != 'string') return false // we only process strings!
	// @ts-ignore
	return (
		!isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str))
	) // ...and ensure strings of whitespace fail
}

export const removeSpacesFromString = (originalString: string) => {
	try {
		let newString = originalString.replace(/\s+/g, '')
		return newString
	} catch (e) {
		console.error('Error Removing Spaces from string', e)
		return originalString
	}
}

export const doesArrayHaveDuplicates = (array: any[]) => {
	return new Set(array).size !== array.length
}

export const getArrayDuplicates = (array: any[]) => {
	let sorted_arr = array.slice().sort() // You can define the comparing function here.
	// JS by default uses a crappy string compare.
	// (we use slice to clone the array so the
	// original array won't be modified)
	let results = []
	for (let i = 0; i < sorted_arr.length - 1; i++) {
		if (sorted_arr[i + 1] == sorted_arr[i]) {
			results.push(sorted_arr[i])
		}
	}
	return results
}

export const getCollectionBlockchainNamesString = (
	collectionNFTEntries: CollectionType['nfts'] | [],
) => {
	let chainsString = ''
	if (
		collectionNFTEntries != null &&
		typeof collectionNFTEntries[Symbol.iterator] === 'function'
	) {
		const chains =
			collectionNFTEntries.map(nftEntry =>
				capitalizeFirstLetter(nftEntry.chain),
			) || []
		const uniqueChains = getFlatArrayUnique(chains)
		chainsString = uniqueChains.toString()
	}
	return chainsString
}

export const getFlatArrayUnique = (array: any[]) => {
	return uniq(array)
}

export const formatMetadataType = (
	metadataEntryType: string,
	metadataEntryName?: string,
) => {
	if (metadataEntryType === 'instagram') {
		return 'Instagram Post'
	} else if (metadataEntryType === 'media') {
		return 'Media Upload'
	} else if (metadataEntryName) {
		return metadataEntryName
	} else {
		let words = metadataEntryType.split(/(?=[A-Z])/)
		words = words.map(
			word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
		)
		const returnedMetadataType = words.join(' ')

		return returnedMetadataType
	}
}

export const formatChainName = (chainName: string) => {
	if (!chainName) return chainName
	return chainName.charAt(0).toUpperCase() + chainName.slice(1).toLowerCase()
}

export const getNFTCollectionInfo = (nftData: nft) => {
	try {
		let artist = {
			name: '',
			verified: false,
			src: undefined,
		}
		if (
			nftData &&
			nftData.raw &&
			Array.isArray(nftData.raw.metadata.attributes)
		) {
			let attrArtist = nftData.raw.metadata.attributes.find(
				(attr: any) => attr.trait_type == 'Artist',
			)
			if (attrArtist) {
				artist = {
					name: attrArtist.value,
					verified: false,
					src: undefined,
				}
			}
		}

		return {
			name: nftData.contract.name
				? nftData.contract.name
				: nftData.collection && nftData.collection.name
					? nftData.collection.name
					: nftData.contract.openSeaMetadata &&
						  nftData.contract.openSeaMetadata.collectionName
						? nftData.contract.openSeaMetadata.collectionName
						: undefined,
			artist: artist,
		} as nftCollectionInfo
	} catch (e) {
		console.log(e)
		return {
			name: '',
			artist: {
				name: '',
				verified: false,
				src: undefined,
			},
		}
	}
}

export const mapIcon = (
	metadataEntryType: string,
	iconSize: 'small' | 'medium' | 'large' = 'large',
	metadataFileType?: string,
) => {
	switch (metadataEntryType) {
		case 'tweet':
			return <XIcon size={iconSize} />
		case 'cast':
			return <FarcasterIcon size={iconSize} />
		case 'instagram':
			return <InstagramIcon size={iconSize} />
		case 'proofOfExhibition':
			return <BidIcon size={iconSize} />
		case 'comment':
			return <CommentIcon size={iconSize} />
		case 'collection':
			return <CollectionIcon size={iconSize} />
		case 'mediaCitation':
			return <NewsIcon size={iconSize} />
		case 'media':
			if (metadataFileType) {
				if (metadataFileType === 'video') {
					return <VideoIcon size={iconSize} />
				} else if (metadataFileType === 'image') {
					return <ImageIcon size={iconSize} />
				} else if (metadataFileType === 'audio') {
					return <AudioIcon size={iconSize} />
				} else if (metadataFileType === 'pdf') {
					return <FileIcon size={iconSize} />
				} else {
					return <ImageIcon size={iconSize} />
				}
			} else {
				return <ImageIcon size={iconSize} />
			}
		case 'offchainLend':
			return <TimerIcon size={iconSize} />
		case 'offchainSale':
			return <PricetagIcon size={iconSize} />
		case 'countersign':
			return <CountersignIcon size={iconSize} />
		case 'rollup':
			return <RollupIcon size={iconSize} />
		case 'bulk':
			return <BulkIcon size={iconSize} />
		default:
			return <PlaceholderIcon size={iconSize} />
	}
}

export const mapSigners = (metadataEntry: any) => {
	const signers = []
	if (metadataEntry.template.slug === 'countersign') {
		signers.push({
			wallet: metadataEntry.originalReference.address,
			role: 'Main signer',
			status: 'Completed',
		})
		signers.push({
			wallet: metadataEntry.address,
			role: 'Countersigner',
			status: 'Completed',
		})
	} else {
		signers.push({
			wallet: metadataEntry.address,
			role: 'Main signer',
			status: 'Completed',
		})
	}

	if (metadataEntry.template.slug == 'proofOfExhibition') {
		if (metadataEntry.countersigningAddress) {
			signers.push({
				wallet: metadataEntry.countersigningAddress,
				role: 'Countersigner',
				status: mapStatus(metadataEntry),
			})
		} else {
			if (
				metadataEntry.properties.artistInitiated === undefined ||
				metadataEntry.properties.artistInitiated
			) {
				if (metadataEntry.properties.receiverAddress) {
					signers.push({
						wallet: metadataEntry.properties.receiverAddress,
						role: 'Countersigner',
						status: mapStatus(metadataEntry),
					})
				}
			} else {
				if (metadataEntry.properties.ownerAddress) {
					signers.push({
						wallet: metadataEntry.properties.ownerAddress,
						role: 'Countersigner',
						status: mapStatus(metadataEntry),
					})
				}
			}
		}
	}

	if (
		metadataEntry.template.slug == 'offchainLend' &&
		metadataEntry.properties.ownerAddress
	) {
		signers.push({
			wallet: metadataEntry.properties.ownerAddress,
			role: 'Countersigner',
			status: mapStatus(metadataEntry),
		})
	}

	if (
		metadataEntry.template.slug == 'offchainSale' &&
		metadataEntry.properties.receiverAddress
	) {
		signers.push({
			wallet: metadataEntry.properties.receiverAddress,
			role: 'Countersigner',
			status: mapStatus(metadataEntry),
		})
	}

	return signers
}

export const mapStatus = (metadataEntry: any) => {
	// If there's no provided countersign wallet, return status null
	if (
		metadataEntry.properties.artistInitiated === undefined ||
		metadataEntry.properties.artistInitiated
	) {
		if (!metadataEntry.properties.receiverAddress) return 'Completed'
	} else {
		if (!metadataEntry.properties.ownerAddress) return 'Completed'
	}
	//TODO string comparison can be removed when the BE returns a boolean instead of a string for isReattested
	if (
		(metadataEntry.isReattested === true ||
			metadataEntry.isReattested === 'true') &&
		metadataEntry.properties.countersignRequired === true
	)
		return 'Completed'
	if (
		(metadataEntry.isReattested === true ||
			metadataEntry.isReattested === 'true') &&
		metadataEntry.properties.countersignRequired === false
	)
		return 'Accepted'
	if (
		metadataEntry.isReattested !== true &&
		metadataEntry.properties.countersignRequired === false
	)
		return 'Completed'
	if (
		metadataEntry.isReattested !== true &&
		metadataEntry.properties.countersignRequired === true
	)
		return 'Pending'
	return ''
}

export function mapPlatform(collectionAttestation: AttestationType) {
	if (!collectionAttestation || !collectionAttestation.properties) return null
	const link = (collectionAttestation.properties as CollectionType)
		.marketplaceUrls
	if (!link) return null
	const url = new URL(link)
	if (url.hostname == 'exchange.art') {
		return 'ExhangeArt'
	} else if (url.hostname == 'solana.fm') {
		return 'Solana.fm'
	} else if (url.hostname == 'opensea.io') {
		return 'OpenSea'
	} else if (url.hostname == 'etherscan.io') {
		return 'Etherscan'
	} else if (url.hostname == 'basescan.io' || url.hostname === 'basescan.org') {
		return 'BaseScan'
	} else if (url.hostname == 'blur.io') {
		return 'Blur'
	} else if (url.hostname == 'zora.co') {
		return 'Zora.io'
	} else if (url.hostname == 'tzkt.io') {
		return 'TzKT'
	} else if (url.hostname == 'objkt.com') {
		return 'objkt'
	} else if (url.hostname === 'shapescan.xyz') {
		return 'Shapescan'
	} else if (url.hostname === 'highlight.xyz') {
		return 'Highlight.xyz'
	}
}

export const getNFTMarketplaceURL = (nftData: nft) => {
	if (nftData.chain == 'ethereum')
		return `https://opensea.io/assets/${nftData.chain}/${nftData.contract.address}/${nftData.tokenId}`
	if (nftData.chain == 'base')
		return `https://opensea.io/assets/${nftData.chain}/${nftData.contract.address}/${nftData.tokenId}`
	if (nftData.chain == 'polygon')
		return `https://opensea.io/assets/matic/${nftData.contract.address}/${nftData.tokenId}`
	if (nftData.chain == 'shape')
		return `https://highlight.xyz/mint/shape:${nftData.contract.address}/t/${nftData.tokenId}`
	if (nftData.chain == 'solana') {
		if (nftData.token_info && nftData.token_info.supply > 1) {
			return `https://exchange.art/editions/${nftData.tokenId}`
		} else {
			return `https://exchange.art/single/${nftData.tokenId}`
		}
	}
	if (nftData.chain == 'zora')
		return `https://zora.co/collect/zora:${nftData.contract.address}/${nftData.tokenId}`
	if (nftData.chain == 'tezos')
		return `https://objkt.com/tokens/${nftData.contract.address}/${nftData.tokenId}`
	return ''
}

export const getNFTMarketplaceIcon = (nftData: nft) => {
	if (nftData.chain == 'ethereum') return <OpenSeaIcon />
	if (nftData.chain == 'base') return <OpenSeaIcon />
	if (nftData.chain == 'solana') return <ExchangeArtIcon />
	if (nftData.chain == 'zora') return <ZoraIcon />
	if (nftData.chain == 'tezos') return <ObjktIcon />
	if (nftData.chain == 'polygon') return <OpenSeaIcon />
	if (nftData.chain == 'shape') return <HighlightIcon />
	return <OpenSeaIcon />
}

export const getNFTScanURL = (nftData: nft) => {
	if (nftData.chain == 'ethereum')
		return `https://etherscan.io/nft/${nftData.contract.address}/${nftData.tokenId}`
	if (nftData.chain == 'base')
		return `https://basescan.org/token/${nftData.contract.address}?a=${nftData.tokenId}`
	if (nftData.chain == 'polygon')
		return `https://polygonscan.com/nft/${nftData.contract.address}/${nftData.tokenId}`
	if (nftData.chain == 'shape')
		return `https://shapescan.xyz/token/${nftData.contract.address}/instance/${nftData.tokenId}`
	if (nftData.chain == 'solana')
		return `https://solana.fm/address/${nftData.tokenId}?cluster=mainnet-alpha`
	if (nftData.chain == 'zora')
		return `https://explorer.zora.energy/token/${nftData.contract.address}/instance/${nftData.tokenId}`
	if (nftData.chain == 'tezos')
		return `https://tzkt.io/${nftData.contract.address}/tokens/${nftData.tokenId}`
}

export const getNFTScanIcon = (nftData: nft) => {
	if (nftData.chain == 'ethereum') return <EtherscanIcon />
	if (nftData.chain == 'base') return <EtherscanIcon />
	if (nftData.chain == 'solana') return <SolanaFMIcon />
	if (nftData.chain == 'zora') return <ZoraIcon />
	if (nftData.chain == 'tezos') return <TZKTIcon />
	if (nftData.chain == 'polygon') return <PolygonIcon />
	if (nftData.chain == 'shape') return <ShapeIcon />
	return <EtherscanIcon />
}

export function getContractAddressExplorerUrl(chain: string) {
	switch (chain) {
		case 'ethereum':
			return 'https://etherscan.io/address/'
		case 'solana':
			return 'https://explorer.solana.com/address/'
		case 'base':
			return 'https://basescan.org/address/'
		case 'polygon':
			return 'https://polygonscan.com/address/'
		case 'shape':
			return 'https://shapescan.xyz/address/'
		case 'tezos':
			return 'https://tzkt.io/'
		case 'zora':
			return 'https://explorer.zora.energy/address/'
		default:
			return ''
	}
}

export function getHashBaseUrl(chain: string) {
	switch (chain) {
		case 'ethereum':
			return 'https://etherscan.io/tx/'
		case 'solana':
			return 'https://explorer.solana.com/tx/'
		case 'base':
			return 'https://basescan.org/tx/'
		case 'polygon':
			return 'https://polygonscan.com/tx/'
		case 'shape':
			return 'https://shapescan.xyz/tx/'
		case 'tezos':
			return 'https://tzkt.io/'
		case 'zora':
			return 'https://explorer.zora.energy/tx/'
		default:
			return ''
	}
}

export const mapEventData = (
	eventResponse: eventData,
	user?: { username: string; metadata: { avatar?: string } },
) => {
	let {
		eventTitle,
		eventDescription,
		eventLocation,
		dateStart,
		dateEnd,
		eventLink,
		eventCID,
		eventImageUrl,
	} = eventResponse
	let event = {
		eventTitle,
		eventDescription,
		eventLocation,
		dateStart,
		dateEnd,
		eventCID,
		eventImageUrl,
		ogimage: eventImageUrl,
		eventLink: eventLink,
		artistName: user ? user.username : 'Unknown username',
		artistPFP:
			user && user.metadata && user.metadata.avatar
				? user.metadata.avatar
				: fallbackAvatarImage,
	} as eventData
	return event
}

export function isValidTezosAddress(address: string) {
	try {
		const result = validateAddress(address)
		return result === 3 && address.startsWith('KT')
	} catch (error) {
		return false
	}
}

export function isValidEthereumAddress(address: string) {
	const regex = /^(0x)?[0-9a-fA-F]{40}$/
	return regex.test(address)
}

export function isValidSolanaAddress(address: string) {
	try {
		new PublicKey(address)
		return true
	} catch (error) {
		return false
	}
}

export const validateContract = (chainSlug: string, contract: string) => {
	let isValidContract = false
	if (chainSlug && chainSlug !== '') {
		if (chainSlug === 'solana') {
			if (contract === '') {
				isValidContract = true
			}
		} else if (chainSlug === 'tezos') {
			isValidContract = isValidTezosAddress(contract)
		} else {
			isValidContract = isValidEthereumAddress(contract)
		}
		if (isValidContract) {
			return { isValid: true, message: '' }
		} else {
			return {
				isValid: false,
				message: `This is not a Valid ${capitalizeFirstLetter(chainSlug)} Contract.`,
			}
		}
	} else {
		return {
			isValid: false,
			message: 'Please select a chain to validate contract.',
		}
	}
}

export async function asyncIsValidSolanaAddress(address: string) {
	try {
		let pubkey = new PublicKey(address)
		let isSolana = await PublicKey.isOnCurve(pubkey.toBuffer())
		return isSolana
	} catch (e) {
		return false
	}
}

export const isMatchingUserAddressForCIDAddress = (
	cidAddress: string,
	userWallets: JwtVerifiedCredential[],
) => {
	if (userWallets.length > 0) {
		for (let i = 0; i < userWallets.length; i++) {
			const currentWallet = userWallets[i]
			// @ts-ignore
			const walletAddress =
				currentWallet.chain === 'eip155'
					? currentWallet.address.toLowerCase()
					: currentWallet.address
			if (
				(currentWallet.chain === 'eip155'
					? cidAddress.toLowerCase()
					: cidAddress) === walletAddress
			) {
				return true
			}
		}
	}
	return false
}

export async function asyncTruncateStringInMiddle(str: string) {
	if (!str) return ''
	if (str.includes('.eth')) return str
	if (!isValidEthereumAddress(str) && (await !asyncIsValidSolanaAddress(str)))
		return str
	if (str.length > 8) {
		return (
			str.substring(0, 4) + '...' + str.substring(str.length - 4, str.length)
		)
	} else {
		return str
	}
}

export const removeEmpty = (obj: any) => {
	let newObj = {}
	Object.keys(obj).forEach(key => {
		// @ts-ignore
		if (obj[key] === Object(obj[key]))
			// @ts-ignore
			newObj[key] = removeEmpty(obj[key])
		// @ts-ignore
		else if (obj[key] !== undefined) newObj[key] = obj[key]
	})

	return newObj
}

export const prepGAProperties = (obj: any) => {
	let newObj = {}
	Object.keys(obj).forEach(key => {
		// @ts-ignore
		if (obj[key] === Object(obj[key]))
			// @ts-ignore
			newObj[snakecase(key)] = prepGAProperties(obj[key])
		// @ts-ignore
		else if (obj[key] !== undefined) newObj[snakecase(key)] = obj[key]
	})

	return newObj
}

export const socialPlatformMapping = {
	Tweet: 'Twitter',
	Cast: 'Farcast',
	Instagram: 'Instagram',
}

export const chainIDMapping = {
	ethereum: 'ETH',
	solana: 'SOL',
	base: 'BASE',
	tezos: 'TEZ',
	zora: 'ZORA',
	polygon: 'MATIC',
	shape: 'SHAPE',
}

export type ChainName =
	| 'ethereum'
	| 'solana'
	| 'base'
	| 'tezos'
	| 'zora'
	| 'polygon'
	| 'shape'

export const chainOptions: selectOption[] = [
	{
		name: 'Ethereum',
		icon: <EthereumIcon />,
		slug: 'ethereum',
		symbol: 'ETH',
		version: '1',
	},
	{
		name: 'Base',
		icon: <BaseIcon />,
		slug: 'base',
		symbol: 'BASE',
		version: '1',
	},
	{
		name: 'Zora',
		icon: <ZoraIcon />,
		slug: 'zora',
		symbol: 'ZORA',
		version: '1',
	},
	{
		name: 'Solana',
		icon: <SolanaIcon />,
		slug: 'solana',
		symbol: 'SOL',
		version: '1',
	},
	{
		name: 'Tezos',
		icon: <TezosIcon />,
		slug: 'tezos',
		symbol: 'XTZ',
		version: '1',
	},
	{
		name: 'Polygon',
		icon: <PolygonIcon />,
		slug: 'polygon',
		symbol: 'MATIC',
		version: '1',
	},
	{
		name: 'Shape',
		icon: <ShapeIcon />,
		slug: 'shape',
		symbol: 'SHAPE',
		version: '1',
	},
]

export const evmChainSlugs = ['ethereum', 'base', 'zora', 'polygon', 'shape']

export const mapChainSlugToIcon = (slug: string) => {
	const chainEntry = chainOptions.find(chain => chain.slug === slug)
	if (chainEntry) {
		return chainEntry.icon
	} else {
		return null
	}
}

const chainArray: { name: string; id: string }[] = []
for (const [key, value] of Object.entries(chainIDMapping)) {
	chainArray.push({ name: key, id: value })
}

export const chainNameIDArray = chainArray

export const capitalizeFirstLetter = (val: string) =>
	val.charAt(0).toUpperCase() + val.slice(1)

export const chainDropdownOptionArray = chainArray.map(option => ({
	name: capitalizeFirstLetter(option.name),
	slug: option.name,
}))

export function truncateStringInMiddle(str: string) {
	if (!str) return ''
	if (str.includes('.eth')) return str
	if (
		!isValidEthereumAddress(str) &&
		!isValidSolanaAddress(str) &&
		!isValidTezosAddress(str) &&
		str.length > 20
	)
		return str.substring(0, 20)
	if (str.length > 8) {
		return (
			str.substring(0, 4) + '...' + str.substring(str.length - 4, str.length)
		)
	} else {
		return str
	}
}

export function lessTruncateStringInMiddle(str: string) {
	if (!str) return ''
	if (str.includes('.eth')) return str
	if (
		!isValidEthereumAddress(str) &&
		!isValidSolanaAddress(str) &&
		str.length > 20
	)
		return str.substring(0, 20)
	if (str.length > 30) {
		return (
			str.substring(0, 15) + '...' + str.substring(str.length - 15, str.length)
		)
	} else {
		return str
	}
}

export function variableTruncateStringInMiddle(str: string, length: number) {
	if (!str) return ''
	if (str.length > length) {
		return (
			str.substring(0, length / 2) +
			'...' +
			str.substring(str.length - length / 2, str.length)
		)
	} else {
		return str
	}
}

export function formatDateToMMMYY(dateString: string) {
	const date = new Date(dateString)
	const options = { year: '2-digit', month: 'short' }
	const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)

	// Extracting the three-letter month abbreviation and two-digit year
	const [month, year] = formattedDate.split(' ')

	return `${month}-${year}`
}

export function formatAITimestamp(dateString: string) {
	if (!dateString) return 'Unknown'

	const date = new Date(dateString)

	// Format the date
	const formattedDate =
		date.toLocaleString('en-US', {
			timeZone: 'UTC',
			year: 'numeric',
			month: 'short',
			day: '2-digit',
			hour: 'numeric',
			minute: '2-digit',
			hour12: true,
		}) + ' UTC'

	return formattedDate
}

export const getReferenceURL = (attestation: AttestationType) => {
	if (!attestation) return ''
	if (attestation.references?.entry?.chain || attestation.references.chain) {
		return getNFTProfileURL(attestation)
	}
	if (
		attestation.references.collection ||
		attestation.references?.entry?.collection ||
		attestation.template.slug === 'collection'
	) {
		return getCollectionProfileURL(attestation)
	}
}

export const getNFTProfileURL = (
	nftData: nft | AttestationType,
	atomicLoreUrl?: string,
	selectedChain?: string,
) => {
	let chain
	let contractAddress
	let tokenId
	let tokenAddress

	try {
		if (
			nftData.references &&
			((nftData.references.entry && nftData.references.entry.chain) ||
				nftData.references.chain)
		) {
			chain = nftData.references.entry
				? nftData.references.entry.chain.name
				: nftData.references.chain.name
			contractAddress = nftData.references.entry
				? nftData.references.entry.nft.contractAddress
				: nftData.references.nft.contractAddress
			tokenId = nftData.references.entry
				? nftData.references.entry.nft.tokenId
				: nftData.references.nft.tokenId
			//TODO should we use token id or token address?
			tokenAddress = nftData.references.entry
				? nftData.references.entry.nft.tokenAddress
				: nftData.references.nft.tokenAddress
			if (tokenAddress) tokenId = tokenAddress
		} else {
			chain = selectedChain ? selectedChain : nftData.chain
			contractAddress = nftData.contract
				? nftData.contract.address
				: nftData.contractAddress
			tokenId = nftData.tokenId
		}

		if (chain && tokenId) {
			return `${atomicLoreUrl ? atomicLoreUrl : ''}/nftProfile/${chain}${chain != 'solana' ? '/' + contractAddress : ''}/${tokenId}`
		} else {
			return undefined
		}
	} catch (e) {
		console.log(e)
		return ''
	}
}

export const getCollectionNFTProfileURL = (
	nftData: CollectionNFTEntry,
	atomicLoreUrl?: string,
) => {
	let chain = nftData.chainName
	let contractAddress = nftData.contractAddress
	let tokenId = nftData.tokenId

	try {
		return `${atomicLoreUrl ? atomicLoreUrl : ''}/nftProfile/${chain}${chain != 'solana' ? '/' + contractAddress : ''}/${tokenId}`
	} catch (e) {
		console.log(e)
		return ''
	}
}

export const getMetadataProfileURL = (
	metadata: AttestationType,
	atomicLoreUrl?: string,
) => {
	// Unsigned or malformed entries have no metadata profile URL
	if (!metadata.references || !metadata.references.attestationIPFSCID) {
		return ''
	}

	// When this function is used from a SSR component, it expects the atomicLoreUrl to be passed in
	// Otherwise, it will use the window.location.origin
	let baseUrl: string | undefined = typeof window !== 'undefined' ? window?.location?.origin : undefined
	baseUrl = baseUrl || atomicLoreUrl
	baseUrl = baseUrl || ''

	if (metadata.template.slug === 'revoke') {
		if (metadata?.originalReference?.template?.slug === 'collection') {
			return `${baseUrl}/metadataProfile/collection/${metadata.references.attestationIPFSCID}`
		}
	}

	if (metadata.template.slug === 'collection') {
		return `${baseUrl}/metadataProfile/collection/${metadata.references.attestationIPFSCID}`
	}

	const attestationIPFSCID = metadata.references.attestationIPFSCID

	if (!attestationIPFSCID) return ''

	if (metadata.template.slug === 'rollup') {
		return `${baseUrl}/metadataProfile/rollup/${attestationIPFSCID}`
	}

	if (metadata.references.entry.chain || metadata.references.chain) {
		const chain = metadata.references.entry
			? metadata.references.entry.chain.name
			: metadata.references.chain.name
		const contractAddress = metadata.references.entry
			? metadata.references.entry.nft.contractAddress
			: metadata.references.nft.contractAddress
		const tokenId = metadata.references.entry
			? metadata.references.entry.nft.tokenId
			: metadata.references.nft.tokenId
		const tokenAddress = metadata.references.entry
			? metadata.references.entry.nft.tokenAddress
			: metadata.references.nft.tokenAddress

		if (chain == 'solana') {
			return `${baseUrl}/metadataProfile/${chain}/${tokenAddress ? tokenAddress : tokenId}/${attestationIPFSCID}`
		} else {
			return `${baseUrl}/metadataProfile/${chain}/${contractAddress}/${tokenId}/${attestationIPFSCID}`
		}
	}

	if (metadata.references.collection) {
		return `${baseUrl}/metadataProfile/collection/${metadata.references.collection}/${attestationIPFSCID}`
	} else if (metadata.references.entry.collection) {
		return `${baseUrl}/metadataProfile/collection/${metadata.references.entry.collection}/${attestationIPFSCID}`
	} else if (
		metadata.references.entry.nft &&
		metadata.references.entry.nft.collection
	) {
		return `${baseUrl}/metadataProfile/collection/${metadata.references.entry.nft.collection}/${attestationIPFSCID}`
	} else if (metadata.references.targetWallet || metadata.references.entry?.targetWallet) {
		return `${baseUrl}/metadataProfile/wallet/${attestationIPFSCID}`
	}
}

export const getEventURL = (
	metadata: AttestationType,
	atomicLoreUrl?: string,
) => {
	// Unsigned or malformed entries have no metadata profile URL
	if (!metadata.references || !metadata.references.attestationIPFSCID) {
		return ''
	}

	const attestationIPFSCID = metadata.references.attestationIPFSCID

	if (!attestationIPFSCID) return ''

	if (metadata.template.slug === 'rollup') {
		return `${atomicLoreUrl ? atomicLoreUrl : ''}/metadataProfile/event/${attestationIPFSCID}`
	} else {
		return ''
	}
}

export const getCollectionProfileURL = (
	collectionAttestationOrCollectionLore: AttestationType,
	atomicLoreUrl?: string,
) => {
	let cid = undefined
	try {
		if (collectionAttestationOrCollectionLore.references.collection) {
			cid = collectionAttestationOrCollectionLore.references.collection
		} else if (
			collectionAttestationOrCollectionLore.template.slug === 'collection'
		) {
			cid = collectionAttestationOrCollectionLore.references.attestationIPFSCID
		} else if (
			collectionAttestationOrCollectionLore.references.entry?.nft &&
			collectionAttestationOrCollectionLore.references.entry?.nft?.collection
		) {
			cid =
				collectionAttestationOrCollectionLore.references.entry?.nft?.collection
		} else {
			return ''
		}

		if (cid) {
			return `${atomicLoreUrl ? atomicLoreUrl : ''}/collectionProfile/${cid}`
		} else {
			return ''
		}
	} catch (e) {
		console.log(e)
		return ''
	}
}
export const authorProfileUrlFromMetadataEntry = (metadataEntry: AttestationType) => {
	return getUserProfileURL(
		metadataEntry.artistUserId
			? metadataEntry.artistUserId
			: metadataEntry.userId
				? metadataEntry.userId
				: metadataEntry.username,
	)
}

export const getCollectionPreviewURL = (
	collectionAttestation: AttestationType,
	atomicLoreUrl?: string,
) => {
	console.log(collectionAttestation)
	let attestationUid = undefined
	try {
		if (collectionAttestation.template.slug === 'collection') {
			attestationUid = collectionAttestation.attestationUid
				? collectionAttestation.attestationUid
				: collectionAttestation.draftCollectionUid
		} else {
			return ''
		}

		if (attestationUid) {
			return `${atomicLoreUrl ? atomicLoreUrl : ''}/collectionPreview/${attestationUid}`
		} else {
			return ''
		}
	} catch (e) {
		console.log(e)
		return ''
	}
}

export const getUserProfileURL = (
	userIdOrUsername?: string,
	atomicLoreUrl?: string,
) => {
	if (!userIdOrUsername) return ''
	return `${atomicLoreUrl ? atomicLoreUrl : ''}/userProfile/${userIdOrUsername}`
}

export const getShareNFTToXURL = (nft: nft | nft[], atomicLoreUrl: string) => {
	const twitterUrl = 'https://twitter.com/intent/tweet'
	return `${twitterUrl}?text=${encodeURIComponent(`Check out ${nft.name} on Atomic Lore:`)}&url=${encodeURIComponent(getNFTProfileURL(nft, atomicLoreUrl))}`
}

export const getShareAttestationToXURL = (
	attestation: AttestationType,
	atomicLoreUrl: string,
	nft?: nft | nft[],
	targetWallet?: TargetWallet | null
) => {
	const twitterUrl = 'https://twitter.com/intent/tweet'
	if (targetWallet) {
		const attestationWithWallet = {
			...attestation,
			references: {
				...attestation.references,
				targetWallet,
			},
		}
		return `${twitterUrl}?text=${encodeURIComponent(`Check out this ${formatMetadataType(attestationWithWallet.template.slug)} on Atomic Lore:`)}&url=${encodeURIComponent(getMetadataProfileURL(attestationWithWallet, atomicLoreUrl))}`
	} else {
		return `${twitterUrl}?text=${encodeURIComponent(`Check out this ${formatMetadataType(attestation.template.slug)} ${!nft || Array.isArray(nft) ? '' : ` for ${nft.name}`} on Atomic Lore:`)}&url=${encodeURIComponent(getMetadataProfileURL(attestation, atomicLoreUrl))}`
	}
}

export const getShareUserProfileToXURL = (
	user: { id: string; username: string },
	atomicLoreUrl: string,
) => {
	const twitterUrl = 'https://twitter.com/intent/tweet'
	return `${twitterUrl}?text=${encodeURIComponent(`Check out ${user.username}'s profile on Atomic Lore:`)}&url=${encodeURIComponent(getUserProfileURL(user.id, atomicLoreUrl))}`
}

export const scrollToTop = (window: any, elementRef?: any) => {
	try {
		window.scroll({
			top:
				elementRef && elementRef.current
					? elementRef.current?.getBoundingClientRect().top + window.scrollY
					: 0,
			behavior: 'smooth',
		})
	} catch (e) {
		console.error(e)
	}
}

type ChainResourceKey =
	| 'ethereum'
	| 'solana'
	| 'base'
	| 'zora'
	| 'polygon'
	| 'shape'
	| 'tezos'

interface ChainResourceData {
	marketplaceURL: string
	marketplaceIcon: JSX.Element
	scanURL: string
	scanIcon: JSX.Element
}

export const chainResources: Record<ChainResourceKey, ChainResourceData> = {
	ethereum: {
		marketplaceURL: 'https://opensea.io/assets/ethereum/',
		marketplaceIcon: <OpenSeaIcon />,
		scanURL: 'https://etherscan.io/nft',
		scanIcon: <EtherscanIcon />,
	},
	solana: {
		marketplaceURL: 'https://exchange.art/single/',
		marketplaceIcon: <ExchangeArtIcon />,
		scanURL: 'https://solana.fm/address',
		scanIcon: <SolanaFMIcon />,
	},
	base: {
		marketplaceURL: 'https://opensea.io/assets/base/',
		marketplaceIcon: <OpenSeaIcon />,
		scanURL: 'https://basescan.org/token',
		scanIcon: <EtherscanIcon />,
	},
	zora: {
		marketplaceURL: 'https://zora.co/collect/zora:',
		marketplaceIcon: <ZoraIcon />,
		scanURL: 'https://explorer.zora.energy/token',
		scanIcon: <ZoraIcon />,
	},
	polygon: {
		marketplaceURL: 'https://opensea.io/assets/matic/',
		marketplaceIcon: <OpenSeaIcon />,
		scanURL: 'https://polygonscan.com/nft',
		scanIcon: <PolygonIcon />,
	},
	shape: {
		marketplaceURL: 'https://highlight.xyz/mint/shape:',
		marketplaceIcon: <HighlightIcon />,
		scanURL: 'https://shapescan.xyz/token',
		scanIcon: <ShapeIcon />,
	},
	tezos: {
		marketplaceURL: 'https://objkt.com/tokens/',
		marketplaceIcon: <ObjktIcon />,
		scanURL: 'https://tzkt.io',
		scanIcon: <TZKTIcon />,
	},
}

export const isValidChain = (
	chain: string,
): chain is keyof typeof chainResources => {
	return Object.keys(chainResources).some(
		key => key.toLowerCase() === chain.toLowerCase(),
	)
}

export const convertDateToYYYYMMDD = (date: Date) => {
	return date.toISOString().split('T')[0]
}

export const openInNewTab = url => {
	window.open(url, '_blank', 'noreferrer')
}

export const bytesToMB = (bytes: number) => {
	const result = bytes * 0.000001
	return result.toFixed(1)
}

export const getURLForFile = (file: File) => {
	return URL.createObjectURL(file)
}

const readFile = (file: File) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)

		reader.readAsArrayBuffer(file)
	})
}

export const getPageCount = async (file: File) => {
	const arrayBuffer = await readFile(file)
	if (arrayBuffer) {
		const pdf = await PDFDocument.load(arrayBuffer)

		return pdf.getPageCount()
	} else {
		return 0
	}
}

export const downloadFile = async ({
	url,
	fileName,
}: {
	url: string
	fileName: string
}) => {
	// using Java Script method to get PDF file
	try {
		const response = await fetch(url)
		const blob = await response.blob()
		const fileURL = window.URL.createObjectURL(blob)

		// Setting various property values
		let alink = document.createElement('a')
		alink.href = fileURL
		alink.download = fileName
		alink.click()
	} catch (e) {
		console.error('ERROR DOWNLOADING FILE', e)
	}
}
