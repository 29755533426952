const CommentIcon = ({
	size = 'large',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		height={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			minWidth: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
			minHeight: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
		}}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 4.54902C2 3.17756 3.05894 2 4.44444 2H11.5556C12.9411 2 14 3.17756 14 4.54902V9.56863C14 10.9401 12.9411 12.1176 11.5556 12.1176H8.21159L5.71779 13.878C5.51432 14.0216 5.24776 14.0398 5.02662 13.9253C4.80549 13.8107 4.66667 13.5824 4.66667 13.3333V12.1176H4.44444C3.05894 12.1176 2 10.9401 2 9.56863V4.54902ZM4.44444 3.33333C3.8661 3.33333 3.33333 3.84111 3.33333 4.54902V9.56863C3.33333 10.2765 3.8661 10.7843 4.44444 10.7843H5.33333C5.70152 10.7843 6 11.0828 6 11.451V12.0467L7.61554 10.9063C7.72802 10.8269 7.86232 10.7843 8 10.7843H11.5556C12.1339 10.7843 12.6667 10.2765 12.6667 9.56863V4.54902C12.6667 3.84111 12.1339 3.33333 11.5556 3.33333H4.44444Z"
			fill="currentColor"
		/>
	</svg>
)

export default CommentIcon
