const VideoIcon = ({
	size = 'large',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		height={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			minWidth: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
			minHeight: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
		}}
	>
		<g clipPath="url(#clip0_891_50)">
			<path
				d="M21.1667 7.83335L15.3333 12L21.1667 16.1667V7.83335Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.6667 6.16669H4.50001C3.57954 6.16669 2.83334 6.91288 2.83334 7.83335V16.1667C2.83334 17.0872 3.57954 17.8334 4.50001 17.8334H13.6667C14.5872 17.8334 15.3333 17.0872 15.3333 16.1667V7.83335C15.3333 6.91288 14.5872 6.16669 13.6667 6.16669Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_891_50">
				<rect width="20" height="20" fill="none" transform="translate(2 2)" />
			</clipPath>
		</defs>
	</svg>
)

export default VideoIcon
